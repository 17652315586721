import Layout from '../components/layout'
import React from 'react'
import { Gen3Provider } from '../contexts/Gen3Context'
import { Gen2Provider } from '../contexts/Gen2Context'
import BreedStatus from '../components/breed-status'
import UnbredGen2 from '../components/unbred-gen2'

export default function IndexPage({ data }) {

	return (
		<Layout prompt="Select a Gen 2 Bear to Determine Breeding Status">
			<p className="red">Each Gen 2 Bear can only breed once. Check a Bear’s Breeding status to determine if they are eligible for breeding.</p>
			<Gen3Provider>
				<BreedStatus/>
				{/* <Gen2Provider>
					<UnbredGen2/>
				</Gen2Provider> */}
			</Gen3Provider>
		</Layout>
    )
}
