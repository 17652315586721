import React, { useContext, useEffect, useState } from 'react'
import { Gen3Context } from '../contexts/Gen3Context'
import Modal from './modal'

export default function BreedStatus() {

	const { contract, checkBreedingStatus } = useContext(Gen3Context) || {}

	const minToken = 0
	const maxToken = 5000
	const [controlsDisabled, setControlsDisabled] = useState(false)
	const [tokenId, setTokenId] = useState<number>(0)
	const [result, setResult] = useState<number|null>(null)

	async function tokenIdChanged(e: any) {
		// console.log(`tokenIdChanged: ${e.target.value}`)
		setTokenId(e.target.value)
	}

	async function onValueInput(e: any) {
		try {
			let inputValue = parseInt(e.target.value)
			e.target.value = Math.max(Math.min(inputValue, maxToken), minToken)
		} catch {
			e.target.value = 0
		}
	}

	async function checkStatus() {
		console.log(`Checking status of Gen 2 Bear #${tokenId}`)
		setControlsDisabled(true)
		try {
			let mated = await checkBreedingStatus(tokenId)
			console.log(` - status is ${mated ? "already bred" : "not bred"}`)
			setResult(mated)
		} catch (error) {
			console.error(error)
		}
		setControlsDisabled(false)
	}

	function closeModal() {
		setResult(null)
	}

	useEffect(() => {
		setControlsDisabled(contract === null || contract === undefined)
	}, [contract])

	return (
		<>
			<input className="input" disabled={controlsDisabled} type="number" max={maxToken} min={minToken} placeholder="Enter Token ID" onChange={tokenIdChanged} onInput={onValueInput}/>
			<button className="initiate" disabled={controlsDisabled} onClick={checkStatus}><h4>Check Breeding Status</h4></button>
			{result === null ? <></> : <Modal title="Token Status" dismissText="Close" onUserClose={closeModal}>
				<h4>{tokenId}</h4>
				<h3>{result ? "Already Bred" : "Not Bred"}</h3>
			</Modal>}
			{contract === null ? <p>Please ensure your wallet is connected to check the status</p> : <></>}
		</>
	)
}
